import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';

// Crear historial de navegación
export const history = createBrowserHistory();

// Crear componente de rutas con Sentry
const SentryRoute = Sentry.withSentryRouting(Route);

// Configurar Sentry
Sentry.init({
    dsn: 'https://8667cf3ace57c8b3102b80bf7f8e0946@o4507575110795264.ingest.us.sentry.io/4508892033777665',
    integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({
            history
        })
    ],
    tracesSampleRate: 1.0,
    environment: 'local'
});

// Definir componente de rutas
export function AppRouter() {
    return (
        <Router history={history}>
            <Switch>
                <SentryRoute exact path="/" component={() => <div>Inicio</div>} />
                <SentryRoute exact path="/login" component={() => <div>Login</div>} />
                <SentryRoute exact path="/register" component={() => <div>Registro</div>} />
                <SentryRoute exact path="/confirm/:token?" component={() => <div>Confirmar</div>} />
                <SentryRoute
                    exact
                    path="/reset-password"
                    component={() => <div>Reset Password</div>}
                />
                <SentryRoute
                    exact
                    path="/reset-password-step-two"
                    component={() => <div>Reset Password Step 2</div>}
                />
                <SentryRoute
                    exact
                    path="/forgot-password"
                    component={() => <div>Forgot Password</div>}
                />
                <SentryRoute exact path="/contracts" component={() => <div>Contratos</div>} />
                <SentryRoute
                    exact
                    path="/contracts/create"
                    component={() => <div>Crear Contrato</div>}
                />
                <SentryRoute
                    exact
                    path="/contracts/:contract_id/edit"
                    component={() => <div>Editar Contrato</div>}
                />
                <SentryRoute
                    exact
                    path="/contracts/:app_log_id/history"
                    component={() => <div>Historial Contrato</div>}
                />
                <SentryRoute exact path="/providers" component={() => <div>Proveedores</div>} />
                <SentryRoute exact path="/lessors" component={() => <div>Arrendadores</div>} />
                <SentryRoute
                    exact
                    path="/notifications"
                    component={() => <div>Notificaciones</div>}
                />
                <SentryRoute
                    exact
                    path="/notifications/:notification_id"
                    component={() => <div>Detalle Notificación</div>}
                />
                <SentryRoute
                    exact
                    path="/system-configurations"
                    component={() => <div>Configuración del Sistema</div>}
                />
                <SentryRoute exact path="/users" component={() => <div>Usuarios</div>} />
                <SentryRoute
                    exact
                    path="/cost_centers"
                    component={() => <div>Centros de Costo</div>}
                />
                <SentryRoute
                    exact
                    path="/cost_centers/:cost_center_id/payments"
                    component={() => <div>Pagos Centro de Costo</div>}
                />
                <SentryRoute exact path="/dashboard" component={() => <div>Dashboard</div>} />
                <SentryRoute path="*" component={() => <div>404 - Página no encontrada</div>} />
            </Switch>
        </Router>
    );
}
